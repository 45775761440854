<template>
  <v-app>
    <v-dialog
        v-model="dialog"
        max-width="900"
        scrollable>
      <v-card>
        <v-toolbar
            dark>
          <v-card-title>

            <span>Manage expiry date</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-col cols="12">
                  <v-menu
                      v-model="expiry_date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field prepend-inner-icon="mdi-calendar"
                                    v-model="rfid_expiry_date"
                                    label="RFID expiry date"
                                    prepend-icon="event" dense outlined
                                    readonly :error="$v.rfid_expiry_date.$error"
                                    v-bind="attrs"
                                    v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="rfid_expiry_date" @input="expiry_date_menu = false" :min="today"></v-date-picker>
                  </v-menu>
                  <span class="text-danger" v-if="$v.rfid_expiry_date.$error">This information is required.</span>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              class="cancel-btn"
              x-large
              @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn
              color="btn btn-primary"
              dark
              x-large
              :loading="loading"
              @click="updateUser()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import userService from "@/core/services/user/UserService";
import {required} from "vuelidate/lib/validators";
const user=new userService();
export default {
  data(){
    return{
      loading:false,
      dialog:false,
      userLoading:false,
      users:[],
      expiry_date_menu:false,
      rfid_expiry_date:null,
      userDetail:null,
      today: new Date().toISOString().substr(0, 10),
    }
  },
  validations: {
    rfid_expiry_date:{required},
  },
  methods:{
    openDialog(){
      this.dialog=true;
    },
    closeDialog(){
      this.dialog=false;
    },
    manageExpiryDate(item){
      this.openDialog();
      this.resetForm();
      this.userDetail=item;
      this.rfid_expiry_date=item.rfid_expiry_date;
    },
    resetForm(){
      this.rfid_expiry_date=null;
      this.userDetail=null;
    },
    updateUser(){
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.userDetail.rfid_expiry_date=this.rfid_expiry_date;
        user
            .update(this.userDetail.id, this.userDetail)
            .then(response => {
              this.$snotify.success("Manage expiry date successfully");
              this.resetForm();
              this.closeDialog();
              this.loading = false;
              this.$emit('refresh');
            })
            .catch((err) => {

              this.$snotify.error("Oops something went wrong");
            })
            .finally(() => (this.loading = false))
      }
    },


  },
  watch:{

  },
}
</script>