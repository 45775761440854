<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4> Manage RFID cards</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ RFID cards
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('rfid-card', 'list')" @click="addUser()" class="mt-4 btn btn-primary"
                         style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add student/teacher
                  </v-btn>&nbsp;&nbsp;

                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field class="form-control" v-model="search.name" label="Name, phone, email." outlined
                                clearable @input="search.name = $event !== null ? $event : ''" v-on:keyup.enter="getRFIDCardUsers()" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select :items="academicYears" class="form-control" :menu-props="{ button: true, offsetY: true }"
                            v-model="search.academic_year_id" label="Academic year" item-value="id" item-text="year"
                            @input="search.academic_year_id = $event !== null ? $event : ''" outlined
                            v-on:keyup.enter="getRFIDCardUsers()" clearable dense>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select :items="levels" :menu-props="{ button: true, offsetY: true }" class="form-control"
                            v-model="search.level_id" label="Level" item-value="id" item-text="title"
                            @input="search.level_id = $event !== null ? $event : ''" outlined v-on:keyup.enter="getRFIDCardUsers()"
                            @change="getPrograms" clearable dense>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select outlined dense v-model="search.program_id" :items="programs" label="Programs"
                            @input="search.program_id = $event !== null ? $event : ''" item-value="id"
                            :menu-props="{ button: true, offsetY: true }" item-text="title" @change="getGrades"
                            v-on:keyup.enter="getRFIDCardUsers()" clearable>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select outlined dense v-model="search.grade_id" :items="gradesLevels"
                            @input="search.grade_id = $event !== null ? $event : ''" label="Semester/year"
                            :menu-props="{ button: true, offsetY: true }" item-value="id" item-text="title"
                            v-on:keyup.enter="getRFIDCardUsers()" clearable></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select class="form-control" v-model="search.user_type" label="User type" outlined :items="job_types" item-value="name"
                                item-text="role_name"
                                clearable @input="search.user_type = $event !== null ? $event : ''" v-on:keyup.enter="getRFIDCardUsers()" dense>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select class="form-control" v-model="search.rfid_status" label="RFID status" outlined :items="['Issued', 'Not issued', 'Expiring soon', 'Expired']"
                                clearable @input="search.rfid_status = $event !== null ? $event : ''" v-on:keyup.enter="getRFIDCardUsers()" dense>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                      v-model="menu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          append-icon="mdi-calendar"
                          clearable
                          dense
                          label="Date of expiry"
                          outlined
                          readonly
                          v-on:keyup.enter="getRFIDCardUsers"
                          v-bind="attrs"
                          v-model="search.expiry_date"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        no-title
                        @input="menu = false"
                        v-model="search.expiry_date"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                      v-model="menu1"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          append-icon="mdi-calendar"
                          clearable
                          dense
                          label="Date of issue"
                          outlined
                          readonly
                          v-on:keyup.enter="getRFIDCardUsers"
                          v-bind="attrs"
                          v-model="search.issue_date"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        no-title
                        @input="menu1 = false"
                        v-model="search.issue_date"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="9" class="text-right">
                  <v-btn :loading="loading" @click.prevent="getRFIDCardUsers()" class="btn btn-primary w-35 float-right" dark>
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3" style="max-width: 70px !important; white-space: pre-wrap;">Image</th>
                    <th class="px-3">Detail</th>
                    <th class="px-3">Expiry date</th>
                    <th class="px-3">Expiry status</th>
                    <th class="px-3">Added By</th>
                    <th class="px-3">Status</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="users.length > 0" v-for="(item,index) in users" :key="index">
                  <td class="px-3" style="max-width: 70px !important; white-space: pre-wrap;">
                    <div class="symbol-label mt-2">
                      <img v-if="item.image_path && item.image_path.real" :src="item.image_path.real"
                           class="cursor-pointer" alt="" style="height: 30px;">
                      <span v-else class="symbol symbol-35 symbol-light-success">
                          <span class="symbol-label font-size-sm font-weight-bold cursor-pointer">
                            {{ item.first_name ? item.first_name.charAt(0).toUpperCase() : '' }}
                            {{ item.last_name ? item.last_name.charAt(0).toUpperCase() : '' }}</span>
                        </span>
                    </div>
                  </td>
                  <td>
                    <a href="#!" @click.prevent="openSummaryPage(item)" class="mb-1">
                      {{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}
                    </a><span class="badge badge-primary">{{item.account_type}}</span><br />
                    <a href="#!" @click.prevent="openSummaryPage(item)" class="mb-1">
                      <b> {{ item.personal_id }} </b><br /> <b> {{ item.academic_year || 'N/A' }} </b><br /> <b> {{ item.level || 'N/A' }} </b><br /> <b> {{ item.program || 'N/A' }} </b><br />
                    </a>
                  </td>
                  <td>
                    <span class="font-weight-medium">
                      {{item.rfid_expiry_date_format || "N/A"}}
                    </span>
                  </td>

                  <td class="px-3">
                    <span class="badge  text-lg"
                          v-bind:class="{
                          'badge-success': item.rfid_expiry_status == 'RFID Card issued', 'badge-warning': item.rfid_expiry_status == 'RFID Card not issued' ,
                          'badge-info': item.rfid_expiry_status == 'RFID Card Expiring soon',  'badge-danger': item.rfid_expiry_status == 'RFID Card Expired',
                    }">
                      {{ item.rfid_expiry_status }}
                    </span>
                  </td>

                  <td class="px-3">
                    <span v-if="item.addedBy && item.added_date">
                      <b> Added by: </b> <span class="mb-2 badge badge-primary">{{ item.addedBy }} </span>
                      <br />
                      <br />
                      <b>Added date:</b> <span class="mt-2 badge badge-primary"> {{ item.added_date }}</span>
                    </span>
                    <span class="font-weight-medium" v-else>N/A</span>
                  </td>

                  <td class="px-3">
                    <span class="badge  text-lg"
                          v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }">
                      {{ item.is_active ? 'Active' : 'Inactive' }}
                    </span>
                  </td>
                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown size="sm" variant="link"
                                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret
                                  right no-flip>
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('rfid-card', 'edit')">
                            <a class="navi-link" @click="manageExpiryDate(item)">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                              <span class="navi-text">Manage expiry date</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text  tag="div" class="navi-item" v-if="checkIsAccessible('rfid-card', 'delete')">
                            <a class="navi-link" @click="removeRFIDFromUser(item.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                              <span class="navi-text">Remove</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                  <tr v-show="users.length == 0">
                    <td class="px-3 text-center" colspan="5">
                      <strong>No data available.</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                  v-show="users.length > 0"
                  @input="getRFIDCardUsers"
                  class="pull-right mt-7"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <add-new-user ref="add-new-user" @refresh="getRFIDCardUsers"></add-new-user>
      <manage-expiry-date ref="manage-expiry-date" @refresh="getRFIDCardUsers"></manage-expiry-date>
    </div>
  </v-app>
</template>
<script>
import AddNewUser from "@/view/pages/rfid-card/AddNewUser.vue";
import ManageExpiryDate from "@/view/pages/rfid-card/ManageExpiryDate.vue";
import userService from "@/core/services/user/UserService";
import RoleService from "@/core/services/user/role/RoleService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import LevelService from "@/core/services/level/LevelService";

const user = new userService();
const roleService = new RoleService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService();

export default {
  components:{
    AddNewUser,
    ManageExpiryDate
  },
  data(){
    return{
      search:{
        name: '',
        user_type: '',
        academic_year_id: '',
        level_id: '',
        program_id: '',
        rfid_status: '',
        expiry_date: '',
        issue_date: '',
      },
      job_types: [],
      levels: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      loading:false,
      menu:false,
      menu1:false,
      users:[],
      page: null,
      total: 0,
      totalPage: null,
      perPage: 25,
    }
  },
  mounted() {
    this.getRFIDCardUsers();
    this.getRoles();
    this.getLevels()
    this.getAcademicYears()
  },
  methods:{
    addUser(){
      this.$refs['add-new-user'].addNewRFIDCard()
    },
    manageExpiryDate(item){
      this.$refs['manage-expiry-date'].manageExpiryDate(item)
    },
    getRoles() {
      roleService.all().then(response => {
        let types= response.data.data;
        types.forEach(type => {
          if(type.name == 'staff' || type.name == 'student' || type.name == 'teacher') {
            this.job_types.push(type);
          }
        })

      });
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        if (this.search.id && this.search.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.search.level_id).then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;

      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then(response => {
        this.gradesLevels = response.data;
      });
    },
    getRFIDCardUsers(){
      this.loading=true;
      user
          .getAllRFIDUsers(this.search,this.page)
          .then(response => {
            this.users = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch(error => {

          })
          .finally(() => {
            this.loading=false;
          })
    },
    openSummaryPage(item) {
      if(this.checkIsAccessible('user', 'list')) {
        this.$router.push({
          name: "students-summary",
          params: { id: item.id, type: item.type[0] }
        });
      }
    },
    removeRFIDFromUser(userId){
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            let data={};
            data.is_rfid_expiry=0;
            data.rfid_id = null;
            data.rfid_expiry_date=null;
            user
                .update(userId,data)
                .then(response => {
                  this.$snotify.success("Information removed");
                  this.getRFIDCardUsers();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },
  }
}
</script>