<template>
  <v-app>
    <v-dialog
        v-model="dialog"
        max-width="900"
        scrollable>
      <v-card>
        <v-toolbar
            dark>
          <v-card-title>

            <span>{{ title }} new student/teacher.</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12">
                <v-autocomplete clearable :loading="userLoading"  label="Add student/teacher" prepend-inner-icon="mdi-magnify" :items="users" outlined dense @change="changeUser" class="form-control-solid" :search-input.sync="userSearch" v-model="userDetail" :error="$v.userDetail.$error" :filter="() => true"  item-text="display_text"  return-object>
                </v-autocomplete>
                <span class="text-danger" v-if="$v.userDetail.$error">This information is required.</span>
              </v-col>
              <v-col cols="12" v-if="userDetail">
                  <v-row>
                    <v-col>
                      <v-text-field
                          outlined
                          dense
                          v-model="rfid_id"
                          type="number"
                          label="RFID card number"
                          :error="$v.rfid_id.$error"
                      >
                      </v-text-field>
                      <span class="text-danger" v-if="$v.rfid_id.$error">This information is required</span>
                    </v-col>

                    <v-col>
                      <v-menu
                          v-model="expiry_date_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field prepend-inner-icon="mdi-calendar"
                                        v-model="rfid_expiry_date"
                                        label="RFID expiry date"
                                        prepend-icon="event" dense outlined
                                        readonly :error="$v.rfid_expiry_date.$error"
                                        v-bind="attrs"
                                        v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker no-title v-model="rfid_expiry_date" @input="expiry_date_menu = false"  :min="today"></v-date-picker>
                      </v-menu>
                      <span class="text-danger" v-if="$v.rfid_expiry_date.$error">This information is required.</span>
                    </v-col>
                  </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              class="cancel-btn"
              x-large
              @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn
              v-if="checkIsAccessible('rfid-card', 'edit')"
              color="btn btn-primary"
              dark
              x-large
              :loading="loading"
              @click="updateUser()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import userService from "@/core/services/user/UserService";
import {required} from "vuelidate/lib/validators";
const user=new userService();
export default {
  data(){
    return{
      loading:false,
      title:null,
      dialog:false,
      userLoading:false,
      users:[],
      userSearch:null,
      rfid_id:null,
      userDetail:null,
      expiry_date_menu:false,
      rfid_expiry_date:null,
      today: new Date().toISOString().substr(0, 10),
    }
  },
  validations: {
    userDetail:{required},
    rfid_id:{required},
    rfid_expiry_date:{required},
  },
  methods:{
    openDialog(){
      this.dialog=true;
    },
    closeDialog(){
      this.dialog=false;
    },
    addNewRFIDCard(){
      this.title='Add';
      this.openDialog();
      this.resetForm();
    },
    resetForm(){
      this.$v.$reset();
      this.rfid_expiry_date=null;
      this.userDetail=null;
      this.userSearch=null;
    },
    updateUser(){
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.userDetail.from_rfid = 1;
        this.userDetail.is_rfid_expiry = 0;
        this.userDetail.rfid_id = this.rfid_id;
        this.userDetail.rfid_expiry_date=this.rfid_expiry_date;
        user
            .update(this.userDetail.id, this.userDetail)
            .then(response => {
              this.$snotify.success("New user added successfully");
              this.resetForm();
              this.closeDialog();
              this.loading = false;
              this.$emit('refresh');
            })
            .catch((err) => {

              this.$snotify.error("Oops something went wrong");
            })
            .finally(() => (this.loading = false))
      }
    },
    changeUser(){
      if(this.userDetail && this.userDetail.is_rfid_expiry){
        this.userDetail=null;
        this.userSearch=null;
        this.users=[];
        this.$snotify.error("User already on lists");
      }
    },
    async searchUser(data) {
      this.userLoading=true;
      user
          .searchUserForChat(data)
          .then((response) => {
            this.users = [];
            let users=[];
            users = response.data.users;
            if(users.length > 0){
              users.map((user) => {
                user.display_text = `${user.personal_id.toUpperCase()} - ${user.full_name} - ${user.email}`
                this.users.push(user);
              });
            }else{
              this.users=[];
            }

          })
          .catch(err => {

          })
          .finally(() => (this.userLoading = false))
    },
  },
  watch:{
    userSearch(val) {
      let data = {
        info:val,
      };
      this.searchUser(data);
    },
  },
}
</script>